/**
 * Vuetify Styles Overrides
 */
.v-application .d-flex {
  min-width: 0;
}

.v-application p {
  margin-bottom: 20px;
}

.v-application .text-number {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji !important;
}

.v-list-item__title {
  font-size: 0.975rem;
  font-weight: 600;
}

.v-list-item__action:first-child,
.v-list-item__icon:first-child {
  margin-right: 14px !important;
}

.v-application--is-rtl .v-list-item__action:first-child,
.v-application--is-rtl .v-list-item__icon:first-child {
  margin-right: 0 !important;
  margin-left: 14px !important;
}

.v-list-item__action:first-child,
.v-list-item__icon:first-child {
  margin-right: 14px !important;
}

.v-list-group__header__append-icon .v-icon {
  font-size: 1rem;
}

.v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
  min-width: 0 !important;
}

.v-list-item__icon {
  margin: auto;
  justify-content: center;
}

.v-list-group--sub-group .v-list-group__header {
  padding-left: 8px !important;
}

.v-list-group__items {
  margin-bottom: 8px;
}

.v-navigation-drawer__content {
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 0px;
  padding: 8px 0;
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 15px, 100% 15px;
  background-attachment: local, local, scroll, scroll;
}

.theme--dark .v-navigation-drawer__content {
  background: none;
}

.v-data-table table {
  padding: 4px;
  padding-bottom: 8px;
}
.v-data-table table th {
  text-transform: uppercase;
  white-space: nowrap;
}
.v-data-table table td {
  border-bottom: 0 !important;
}
.v-data-table table tbody tr {
  transition: box-shadow 0.2s, transform 0.2s;
}
.v-data-table table tbody tr:not(.v-data-table__selected):hover {
  box-shadow: 0 3px 15px -2px rgba(0, 0, 0, 0.12);
  transform: translateY(-4px);
}

.v-tabs-items {
  background-color: transparent !important;
}

.theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #273743;
}

.row {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}