
.v-btn[data-v-02f7c882]::before {
  background-color: transparent;
}
.box[data-v-02f7c882] {
  display: flex;
  align-items: flex-start;
  height: 70px;
  width: 84%;
  flex-direction: row;
  flex-wrap: nowrap;
  position: absolute;
}
.center[data-v-02f7c882] {
  float: left;
  width: 100%;
  height: 70px;
  overflow: hidden;
  white-space: nowrap;
}
.internal[data-v-02f7c882] {
  width: auto;
  height: 60px;
  display: inline-block;
  position: relative;
}
