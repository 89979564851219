
.breadcrumb[data-v-e1f47466]:hover {
  cursor: pointer;
}
.v-btn[data-v-e1f47466]::before {
  background-color: transparent;
}
.iconStyle[data-v-e1f47466] {
  background-color: #203249 !important;
}
.filterStyle[data-v-e1f47466] {
  margin-top: 42px;
  margin-left: 5px;
  margin-right: 10px;
  padding: 0px;
  width: 100%;
}
.searchField[data-v-e1f47466] {
  height: 20px;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
  z-index: 999999;
}
.appbar[data-v-e1f47466] {
  z-index: 999;
}
.footerbar[data-v-e1f47466] {
  z-index: 99999999;
}
