
/**
 * Transition animation between pages
 */
.fade-enter-active[data-v-7ba5bd90],
.fade-leave-active[data-v-7ba5bd90] {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter[data-v-7ba5bd90],
.fade-leave-active[data-v-7ba5bd90] {
  opacity: 0;
}
