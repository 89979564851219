
.searchField[data-v-0c17871c] {
  height: 20px;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
  z-index: 999999;
}
.appBarClass[data-v-0c17871c] {
  z-index: 999;
}
