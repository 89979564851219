
.container[data-v-d6228a12] {
  padding-left: 0px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 300px;
  min-width: 200px;
  z-index: 99999;
}
.v-text-field[data-v-d6228a12] {
  padding-top: 0px;
  margin-top: 0px;
}
