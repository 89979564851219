
.cardStyle[data-v-f9323668] {
  width: 240px;
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}
.pointerStyle[data-v-f9323668] {
  cursor: pointer;
}
.imageStyle[data-v-f9323668] {
  max-height: 60%;
  max-width: 60%;
  height: 60px;
  width: 60px;
  margin-left: 22%;
}
.box[data-v-f9323668] {
  height: 110px;
  width: 110px;
  transition: box-shadow 0.3s;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
}
.box[data-v-f9323668]:hover {
  box-shadow: 0 0 11px #668ebe;
}
